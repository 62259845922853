<template>
 <base-page>
    <titlu-pagina Titlu="Settings fisa intrare" @on_add_clicked="show_dialog()" />

       <el-card style='margin:5px 0px 5px 0px'>
            
            <el-row >
                <el-col :md="20" :offset="2">
                    <vue-editor v-model="selectedObject.Continut" :editorToolbar="customToolbar" ></vue-editor>
                    <el-button style="float: right; margin-top: 20px;" type="primary" @click="save('my-form')" > Salveaza </el-button>
                </el-col>                                            
            </el-row>
             
       </el-card>

</base-page>
        
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import TitluPagina from '@/widgets/TitluPagina';
    import { VueEditor } from "vue2-editor";


    export default {
        name: "Fisa_intrare_settings",
        extends: BasePage,
        components:{
            'base-page': BasePage,           
            'titlu-pagina': TitluPagina,
            VueEditor
        },
        data () {
            return {
                showPopup: true,
                
                selectedObject: {Nume: '', Continut: ''} ,                    
                                                
                rules: {
                    Continut: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Nume:     [ { required: true, message: 'Campul este obligatoriu' } ], 
                },
                content: "<h1>Some initial content</h1>"   ,
                customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["image", "code-block"]]           
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup          = true;
                this.selectedObject     = {Nume: '', Continut: ''}                                
                var            result   = await this.post("fisa_intrare/edit_settings", { id: id } );
                this.selectedObject     = result.Item;
            },
            
            save: async function(formName) {                
                
                await this.post("fisa_intrare/save_settings", { object: this.selectedObject } );
                this.show_me();                      
            },            
			resetForm(formName) {
                this.$refs[formName].resetFields()
                this.show_me();
				this.showPopup = false
			}
        },
        mounted: function() {
            this.show_me();
            
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>